<template>
   <div class="login">

       <div class="login-box">

           <h1>Scanner</h1>

           <div v-if="errorMessage !== null" class="login__error">{{errorMessage}}</div>

           <div class="form" v-on:submit.prevent="signInUser">
               <form>
                   <div class="form-group field-input">
                       <div class="field-wrap">
                           <div class="wrapper">
                               <input
                                       type="text"
                                       class="form-control"
                                       v-model="username"
                                       placeholder="Username"
                               />
                           </div>
                       </div>
                   </div>
                   <div class="form-group">
                       <div class="field-wrap">
                           <div class="wrapper">
                               <input
                                       type="password"
                                       class="form-control"
                                       v-model="password"
                                       placeholder="Password"
                               />
                           </div>
                       </div>
                   </div>
                   <div class="form-group">
                       <div class="field-wrap">
                           <div class="wrapper">
                               <button class="" @click="signInUser()">Sign in</button>
                           </div>
                       </div>
                   </div>
               </form>
           </div>

       </div>

   </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    export default {
        name: "Login",

        components: {

        },

        data: function () {
            return {
                username: "",
                password: ""
            };
        },

        computed: {
            ...mapGetters({
                error: "error",
                jobs: "jobs",
                eventId: "eventId",
            }),

            isLoading: function () {
                return this.jobs.includes("login");
            },

            errorMessage: function () {
                if (this.error && this.error.message) return 'Invalid username or password';
                return null;
            }

        },

        methods: {
            ...mapActions(["signIn"]),

            signInUser() {
                this.signIn({username: this.username, password: this.password, eventId: this.eventId});
            }
        }
    }
</script>

<style scoped lang="scss">

    .login {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        height: calc(100vh - 75px);
    }

    .login-box {
        background: #fff;
        /*
        border-radius: 6px;
        -webkit-box-shadow: 0 5px 5px -5px #273945, 0 5px 20px -5px rgba(39,57,69,.2);
        box-shadow: 0 5px 5px -5px #273945, 0 5px 20px -5px rgba(39,57,69,.2);
        */
        padding: 50px 40px;
        min-width: 400px;
    }

    .login__error {
        color: $red;
        padding-top: 10px;
    }

    h1 {
        color: #273945;
        font-family: heebo,sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 53px;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .form-control {
        font-family: heebo,sans-serif;
        font-weight: 400;
        color: #52606a;
        font-size: 14px;
        line-height: 21px;
        border: 1px solid #e8eaec;
        background: #fff;
        border-radius: 2px;
        padding: 15px;
        width: 100%;
    }

    button {
        font-family: heebo,sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        padding: 10px 12px;
        border: none;
        background-color: #ffae00;
        color: #fff;
        cursor: pointer;
    }


</style>